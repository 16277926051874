<template>
	<div>
		<div class="main" v-loading="loading">
			<div class="coupon" v-for="(item,index) in list">
				<div class="left">{{$t('discountcoupon')}}</div>
				<div class="price">
					<div class="price-num">{{item.couponPrice}}</div>
					<div class="price-unit">{{$t('yuan')}}</div>
				</div>
				<div class="right">
					<div class="fullAvailable-wrap">
						<div class="fullAvailable">{{$t('full')}}{{item.minPrice}}{{$t('usable')}}</div>
						<div class="time">({{item.usefulTimeStart}}-{{item.usefulTimeEnd}})</div>
					</div>
					
					<div class="status-touse" @click="toUse" v-if="item.status == 2">{{$t('touse')}}</div>
					<div class="status-Used" v-if="item.status == 1">{{$t('haveused')}}</div>
				</div>
			</div>
		</div>
		<div class="emptyView">
			<el-empty v-if="isEmpty == true" :image-size="100"></el-empty>
		</div>
	</div>
	
</template>

<script>
	import {couponList} from '@/api/Home'
	export default {
		props: {},
		computed: {},
		components: {},
		data(){
			return {
				list: [],
				loading: true,
				isEmpty: false,
			}
		},
		created() {
			this.getCouponList();//获取优惠卷列表
		},
		mounted() {
			
		},
		methods: {
			// 去使用优惠卷
			toUse(){
				this.$router.push({
					path: '/musicLibrary/producthmusic'
				})
			},
			//获取优惠卷列表
			getCouponList(){
				couponList({}).then((res) => {
						console.log(res, '我的信息成功');
						this.loading = false
						this.list = res.data
						if(this.list == ''){
							this.isEmpty = true
						}else {
							this.isEmpty = false
						}
						// localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					})
					.catch((err) => {
						console.log(err, '我的信息失败');
					});
			}
			
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .el-loading-mask{
		background-color: rgba(0,0,0,0);
	}
	.emptyView{
		width: 100%;
		display: flex;
		justify-content: center;
		min-height: 100px;
	}
	.main{
		color: #DBB472;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		// margin-left: 28px;
		justify-content: space-between;
		min-height: 100px;
		.coupon{
			background-image: url(../../../assets/images/personalCenter/personal-coupon.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			width: 48%;
			height: 162px;
			display: flex;
			// margin-right: 24px;
			
			margin-bottom: 24px;
			.left{
				writing-mode: vertical-lr;
				// width: 104px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				font-weight: 500;
				color: #DBB472;
				padding-left: 24px;
			}
			.price{
				display: flex;
				align-items: center;
				// text-align: end;
				margin-left: 50px;
				// margin-top: 20px;
				.price-num{
					font-size: 80px;
					font-family: Roadgeek2005Engschrift;
					color: #DBB472;
				}
				.price-unit{
					font-size: 18px;
					font-weight: 600;
					color: #DBB472;
					margin-left: 14px;
					margin-top: 18px;
				}
			}
			.right{
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				padding-right: 30px;
				justify-content: space-around;
				.fullAvailable-wrap{
					display: flex;
					flex-direction: column;
					align-items: center;
					.fullAvailable{
						font-size: 16px;
						font-weight: 600;
						color: #FBD195;
						line-height: 22px;
						margin-bottom: 4px;
					}
					.time {
						font-size: 14px;
						color: #FBD195;
						line-height: 22px;
						margin-right: -20px;
					}
				}
				.status-touse{
					width: 92px;
					height: 36px;
					background: #FBD79B;
					box-shadow: 0px 1px 0px 0px #000000;
					border-radius: 6px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					font-weight: 600;
					color: #191C32;
					cursor: pointer;
				}
				.status-Used{
					width: 92px;
					height: 36px;
					background: #999999;
					box-shadow: 0px 1px 0px 0px #000000;
					border-radius: 6px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					font-weight: 600;
					color: #FFFFFF;
					cursor: pointer;
				}
			}
			
		}
	}
</style>